import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";
import { Helmet } from "react-helmet";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <Helmet>
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "Person",
                "name": "Nisham Ghimire",
                "description": "CS student in Kathmandu University",
                "url": "https://www.nishamghimire.com.np",
                "sameAs": [
                  "https://www.linkedin.com/in/nisham-ghimire-473957170",
                  "https://github.com/nishamghimire5",
                  "https://twitter.com/nishamghimire10"
                ]
              }
            `}
          </script>
        </Helmet>
          <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
           Hi Everyone, I am <span className="purple">Nisham Ghimire </span> 
          from <span className="purple"> Kathmandu, Nepal.</span>
          <br />I am a <span className="purple">CS</span> student at 
            <span className="purple"> Kathmandu University</span>. 
            <br />
            <br />
            Apart from coding, some <span className="purple"> other activities </span> that I love to do:
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Talking about Psychology
            </li>
            <li className="about-activity">
              <ImPointRight /> Playing Games
            </li>
            <li className="about-activity">
              <ImPointRight /> Writing
            </li>
            <li className="about-activity">
              <ImPointRight /> Traveling
            </li>
            <li className="about-activity">
              <ImPointRight /> Watching Movies
            </li>
          </ul>
          <p style={{ color: "rgb(155 126 172)" }}>
            "Learn to conquer your brain and control your thoughts!" <br />{" "}
            <i style={{ fontSize: "15px" }}>then you are unstoppable</i>
          </p>
          <footer className="blockquote-footer">Nisham</footer>
        </blockquote>
        <br />
        <br />
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
