import React from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Github from "./Github";
import Techstack from "./Techstack";
import QuotesPass from "./QuotesPass";
import Aboutcard from "./AboutCard";
import laptopImg from "../../Assets/about.png";
import Toolstack from "./Toolstack";

function About() {
  return (
    <Container fluid className="about-section">
      <Helmet>
        <title>About Me | My Portfolio</title>
      </Helmet>
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              <strong>About Me</strong>
            </h1>
            {/* //about card */}
            <Aboutcard />
          </Col>
          <Col
            md={5}
            style={{ paddingTop: "120px", paddingBottom: "50px" }}
            className="about-img"
          >
            <img src={laptopImg} alt="about" className="img-fluid" />
          </Col>
        </Row>
        <QuotesPass />
        <h1 className="project-heading">
          <strong>Professional <span className="purple">Skillset </span></strong>
        </h1>
        {/* //techstack */}
        <Techstack />

        <h1 className="project-heading">
          <strong>Tools I <span className="purple">Use </span></strong>
        </h1>
        {/* //toolstack */}
        <Toolstack />
        {/* //githubCodeFrequency */}
        <Github />
      </Container>
    </Container>
  );
}

export default About;
