import React from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import kounsel from "../../Assets/Projects/kounsel.png";
import expenseTracker from "../../Assets/Projects/expenseTracker.png";
import senti from "../../Assets/Projects/senti.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Helmet>
        <title>My Recent Works | Portfolio</title>
        <meta name="description" content="Check out my recent works and projects I've worked on." />
        <meta name="keywords" content="projects, recent works, portfolio, Kounsel, Expense Tracker" />
        {/* Add any other necessary meta tags for SEO optimization */}
      </Helmet>
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works</strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={kounsel}
              isBlog={false}
              title="Kounsel"
              description="Kounsel is a platform for students to share their feelings and discuss their mental problems without the fear of being judged."
              ghLink="https://github.com/nishamghimire5/Kounsel"
              demoLink="https://kounsel.vercel.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={expenseTracker}
              isBlog={false}
              title="Expense Tracker"
              description="An expense tracking app built with Flutter which can be used to manage your daily expense details."
              ghLink="https://github.com/nishamghimire5/expense_tracker"
              demoLink=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={senti}
              isBlog={false}
              title="Semti-Surfer"
              description="Senti-Surfer is a web extension that performs sentiment analysis on bilingual social media content to provide valuable insights."
              ghLink="https://github.com/nishamghimire5/senti-surfer-1"
              demoLink=""
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
