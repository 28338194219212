import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiFillInstagram,
  AiOutlineFacebook,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { Helmet } from "react-helmet";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Helmet>
        <title>Nisham Ghimire | Portfolio</title>
        <meta name="description" content="Learn more about Nisham Ghimire - his interests, hobbies, and expertise." />
        {/* Add any other necessary meta tags for SEO optimization */}
      </Helmet>
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
              I love  <b className="purple">Photography and Programming, </b>I think 🤷‍♂️
              <br />
              <br />I love
              <i>
                <b className="purple"> travelling, exploring new places</b> and<b className="purple"> talking to new people </b>
              </i>
              🕺
              <br />
              <br />
              My field of Interest is {" "}
              <i>
              <b className="purple">Business Analytics</b> and
              {" "}
              <b className="purple"> Data Analytics</b> and also
              {" "}
              <b className="purple">
              Cybersecurity
              </b>
              </i>
              💼📊🔒
              <br />
              <br />
              In my free time, I also have passion for
              <i>
                <b className="purple"> writing</b></i>&nbsp;and&nbsp;
              <i>
                <b className="purple">
                  reading books and articles
                </b>
                &nbsp;📖
                <br />
              </i>
              And I love giving people free &nbsp;
              <i>
                <b className="purple">advices and suggestions</b>
              </i>
              &nbsp;😜
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/nishamghimire5"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://facebook.com/nishamghimire5"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineFacebook />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/nisham-ghimire-473957170/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/nishamghimire"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container >
  );
}

export default Home2;
