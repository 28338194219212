import data from '../../Assets/quotes.json'
import Quotes from './Quotes';
import { Row } from "react-bootstrap";

const QuotesPass = () => {

    return (
        <div className=''>
            <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
                <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
                    Some of my <strong className="purple">Quotes</strong>
                </h1>
            </Row>
            {data.map((send, index) => {
                // let number = data.findIndex(obj => obj.name == send.title);
                return (<Quotes key={index} number={index + 1} Myquote={send.content} />)
            }
            )}
            <br />
            <br />
            <br />
            <br />
        </div>
    )
}

export default QuotesPass;