import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
        "Student",
        "Occasional Writer",
        "Data Analytics Enthusiast",
        "Business Analytics Enthusiast",
        "Cybersecurity Enthusiast",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  );
}

export default Type;
