import React from "react";

function Quotes(props) {
    return (
        <div>
            <blockquote className="blockquote mb-0 col-12">
                <p className="my-2" style={{ textAlign: "justify" }}>
                    <h4 className="purple">
                        {props.number}.
                    </h4>
                    {props.Myquote}

                </p>
                <hr />
            </blockquote>
        </div>
    )
}
export default Quotes;   